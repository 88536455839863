import { Col, Collapse, Row, Spin } from 'antd';
import { LIVE_STRESS_DETECTION_PAGE } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stressDetectionServiceBoatsSelector, userOrganizationIdSelector } from 'redux/selector';
import { resetStressDetectionServiceBoats, setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getServiceBoats, startStressEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import PenList from '../PenList';
import ServiceBoatStatus from './ServiceBoatStatus';
import { RadarChartOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';

const StressDetection = () => {
    const { Panel } = Collapse;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [penListState, setPenListState] = useState({ isOpen: false, serviceBoat: null });

    const { data: serviceBoats, loading } = useSelector(stressDetectionServiceBoatsSelector);
    const userOrganizationId = useSelector(userOrganizationIdSelector);

    useEffect(() => {
        dispatch(getServiceBoats());

        return () => {
            dispatch(resetStressDetectionServiceBoats());
        };
    }, []);

    const [
        serviceBoatsWithEngineConfigAndBaseUrl,
        serviceBoatsWithEngineConfig,
        serviceBoatsWithoutEngineConfig
    ] = useMemo(() => {
        const serviceBoatsWithEngineConfigAndBaseUrl = [];
        const serviceBoatsWithEngineConfig = [];
        const serviceBoatsWithoutEngineConfig = [];

        serviceBoats.forEach((boat) => {
            if (boat.hasEngineConfig && boat.hasEngineBaseUrl) {
                serviceBoatsWithEngineConfigAndBaseUrl.push(boat);
            } else if (boat.hasEngineConfig) {
                serviceBoatsWithEngineConfig.push(boat);
            } else {
                serviceBoatsWithoutEngineConfig.push(boat);
            }
        });

        return [
            serviceBoatsWithEngineConfigAndBaseUrl,
            serviceBoatsWithEngineConfig,
            serviceBoatsWithoutEngineConfig
        ];
    }, [serviceBoats]);

    const handlePenListClose = useCallback(() => {
        setPenListState({ isOpen: false, serviceBoat: null });
    }, []);

    const handlePenListOpen = useCallback((serviceBoat) => {
        setPenListState({ isOpen: true, serviceBoat });
    }, []);

    const handleStressEngineStart = useCallback(
        async (penNumber) => {
            try {
                const response = await dispatch(
                    startStressEngine({
                        baseUrl: penListState.serviceBoat?.engineBaseUrl,
                        penNumber,
                        farmerOrganizationId: userOrganizationId
                    })
                ).unwrap();

                if (response.hasBeenStarting) {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveStressDetection.notification.hasStartedDetection'),
                        duration: 15
                    });
                } else {
                    NOTIFICATION_SERVICE.pushNotification({
                        title: t('liveStressDetection.notification.startDetection'),
                        duration: 10
                    });
                }

                dispatch(
                    setStressDetectionStreaming({
                        isDetecting: true,
                        engineBaseUrl: penListState.serviceBoat?.engineBaseUrl,
                        serviceBoatId: penListState.serviceBoat?.id,
                        serviceBoatName: penListState.serviceBoat?.name,
                        penNumber
                    })
                );

                handlePenListClose();
                navigate(LIVE_STRESS_DETECTION_PAGE);

                appInsights.trackEvent({
                    name: 'Start stress detection successfully',
                    properties: {
                        serviceBoatName: penListState.serviceBoat?.name,
                        penNumber
                    }
                });
            } catch (err) {
                alertErrorMessage(err);

                appInsights.trackEvent({
                    name: 'Start stress detection failed',
                    properties: {
                        serviceBoatName: penListState.serviceBoat?.name,
                        penNumber
                    }
                });
            }
        },
        [penListState.serviceBoat, userOrganizationId, handlePenListClose]
    );

    return (
        <div id="stress-detection">
            <div className="mt-10">
                <h2>
                    <RadarChartOutlined className="mr-2" />
                    {t('liveStressDetection.serviceBoatStatus')}
                </h2>

                {loading ? (
                    <Spin />
                ) : serviceBoats.length ? (
                    <>
                        <ServiceBoatList
                            serviceBoats={serviceBoatsWithEngineConfigAndBaseUrl}
                            onPenListOpen={handlePenListOpen}
                        />

                        <ServiceBoatList
                            serviceBoats={serviceBoatsWithEngineConfig}
                            onPenListOpen={handlePenListOpen}
                        />

                        <Collapse ghost>
                            <Panel
                                header={t('liveStressDetection.serviceBoatsWithoutEngineConfig')}
                            >
                                <ServiceBoatList
                                    serviceBoats={serviceBoatsWithoutEngineConfig}
                                    onPenListOpen={handlePenListOpen}
                                />
                            </Panel>
                        </Collapse>
                    </>
                ) : (
                    <p className="m-0 text-base text-red-600">
                        {t('liveStressDetection.noServiceBoatAvailable')}
                    </p>
                )}
            </div>

            <PenList
                isOpen={penListState.isOpen}
                onClose={handlePenListClose}
                onEngineStart={(penNumber) => handleStressEngineStart(penNumber)}
            />
        </div>
    );
};

const ServiceBoatList = ({ serviceBoats = [], onPenListOpen = () => {} }) => {
    if (!serviceBoats.length) return;

    return (
        <Row gutter={[10, 10]} className="mb-3">
            {serviceBoats.map((boat) => (
                <Col xs={12} sm={8} md={6} lg={4} xxl={3} key={boat.id}>
                    <ServiceBoatStatus
                        serviceBoat={boat}
                        key={boat.id}
                        onPenListOpen={onPenListOpen}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default StressDetection;
