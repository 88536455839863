import { ArrowUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useAppAbility from 'casl/can';
import { SITE_MANAGER } from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import DeadFishCounting from './DeadFishCounting';
import './LiveFishEvent.scss';
import StressDetection from './StressDetection';
import { withPageViewTracking } from 'hoc';

const LiveFishEvent = () => {
    const { showLiveDeadFishCounting, showLiveStressDetection } = useFlags();
    const { can } = useAppAbility();
    const [isScrollToTopButtonVisible, setIsScrollToTopButtonVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 0) {
            setIsScrollToTopButtonVisible(true);
        } else {
            setIsScrollToTopButtonVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    if (!showLiveDeadFishCounting && !showLiveStressDetection) {
        return;
    }

    return (
        <div className="live-fish-event">
            {showLiveDeadFishCounting && <DeadFishCounting />}

            {can('manage', SITE_MANAGER, ['all']) && showLiveStressDetection && <StressDetection />}

            <div className="fixed bottom-5 right-5">
                {isScrollToTopButtonVisible && (
                    <Button
                        onClick={scrollToTop}
                        shape="circle"
                        icon={<ArrowUpOutlined />}
                        size="large"
                        className="shadow-sm"
                    />
                )}
            </div>
        </div>
    );
};

export default withPageViewTracking(LiveFishEvent, 'Live Fish Event');
